@import "../../Assets/colors.css";

.detailsMainBox{
    width: 100%;
    background-color: var(--AccentColor);
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
}

.whiteDetailBox{
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    background-color: var(--HighlightColor);
    padding: 40px 50px 55px 50px;
    width: 100%;
    max-width: 400px;
    /* max-height: 460px; */
    gap: 15px;
    margin: auto;
}
.detailSubBox{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.detailTitle{
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
}
.detailTitle2{
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    width: 100%;
    max-width: 150px;
}
.detailData{
    text-transform: none;
}

.addToBtnBox{
    display: flex;
    justify-content: center;
}
.addToContact{
    margin-top: 10px;
    background-color: var(--blackColor) !important;
    text-transform: none;
    font-size: 17px;
    color: var(--AccentColor);
    padding: 5px 10px 5px 10px ;
}