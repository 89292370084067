@import "../../Assets/colors.css";

.loginpage {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: auto;
  /* padding: 3%; */
  /* background-color:var(--MainColor); */
}

.loginLogoBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3%;
  width: 100%;
  height: 100vh;
  /* margin-left: 50px;
    margin-right: 50px; */
}
.loginPageLogo {
  width: 100%;
  max-width: 100px;
}
.loginPageImg {
  width: 100%;
  max-width: 500px;
  margin: auto;
}
.logoName {
  color: var(--HighlightColor);
  font-size: 18px;
  font-weight: 500;
}

.passwordInput {
  margin-top: 10px;
}
.loginFormText {
  font-size: 17px;
  text-align: center;
  color: white !important;
  margin-top: 10px;
}
.loginBtnBox {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.button {
  text-align: center;
  color: white;
  border: 1px solid white;
  /* height: 35px; */
  padding: 4px 4px 4px 4px;
  margin-top: 10px;
  /* width: 180px; */
}
.btnText {
  font-style: normal;
  text-transform: none;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.googleBtnBox {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.loginRightBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3%;
  width: 100%;
  height: 100vh;
  background-color: #f7f7f7;
}
/* .loginRightSubBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 400px;
  /* max-height: 460px; */

.loginRightSubBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 400px;
  background: white;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.loginRightSubBox:hover {
  transform: translateY(-5px) scale(1.02);
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.3);
}

.loginBoxHeadingText {
  font-size: 36px;
  color: rgb(216, 20, 20);
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  /* background: linear-gradient(45deg, #c17755, #9a9494); */
  background: linear-gradient(45deg, #be663e, #252525);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}
.loginBoxTitleText {
  font-size: 16px;
  color: black;
  /* font-family: 'Montserrat'; */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-top: 6px;
}
.googleBtn {
  display: flex;
  gap: 10px;
  width: 100%;
  border: 1px solid var(--dividercolor);
  text-transform: none;
  color: black;
  font-size: 14px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.dividerText {
  font-size: 12px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: black !important;
  border-style: "dashed";
  margin: 15px 0px;
}
.passwordDetailBox {
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.forgotBtn {
  font-size: 12px;
  text-transform: none;
  color: var(--redColor);
  background-color: transparent !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.loginBtn {
  background: linear-gradient(180deg, #be6639, #494445) !important;
  width: 100%;
  text-transform: none;
  font-size: 16px;
  color: white !important;
}
.checkBoxLabel {
  font-size: 14px;
  color: black;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.loginInputHeading {
  font-size: 18px;
  letter-spacing: 3px;
  line-height: normal;
  text-shadow: #9a9494;
  font-weight: 500;
  color: black;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.dontaccText {
  margin: auto;
  font-size: 12px;
  color: var(--fadedBlack);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.signupText {
  font-size: 1rem;
  margin-left: 2px;
  /* color: black; */
  color: black;
}

@media screen and (max-height: 550px) {
  .loginRightBox {
    height: max-content;
  }
  .loginLogoBox {
    height: fit-content;
  }
  .loginPageImg {
    max-width: 350px;
  }
}
@media screen and (min-height: 2100px) {
  .loginPageImg {
    max-width: 800px;
  }
}
@media screen and (max-width: 900px) {
  .loginBoxHeadingText {
    font-size: 30px;
  }
  .loginBoxTitleText {
    font-size: 14px;
  }
  .loginLogoBox {
    height: 100%;
  }
  .loginRightBox {
    padding: 20px;
  }
  .passwordDetailBox {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 350px) {
  .loginBoxHeadingText {
    font-size: 24px;
  }
  .loginBoxTitleText {
    font-size: 10px;
  }
}
.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
  color: black !important;
}
.css-l06th9-MuiFormControl-root-MuiTextField-root input {
  background-color: transparent;
  border-bottom: 1px solid black;
  padding: 0px;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.css-i4bv87-MuiSvgIcon-root {
  color: black;
}

@media (min-width: 1024px) {
  .loginPageImg {
    display: none; /* Hides image on iPad Pro and smaller screens */
  }
  .loginLogoBox {
    display: list-item;
    justify-content: center;
    align-items: center;
    padding: 3%;
    width: 100%;
    height: 100vh;
    /* margin-left: 50px;
      margin-right: 50px; */
  }
  .loginRightBox {
    display: inherit;
    /* flex-direction: column; */
    align-items: center;
    justify-content: flex-start;
    gap: 0%;
    margin-top: 150px;
    width: 2000%;
    /* height: 10000vh; */
    background-color: #f7f7f7;
  }
  .loginPageLogo {
    width: 353%;
    max-width: 156px;
    /* margin-left: 277px; */
    left: 444px;
    position: relative;
    bottom: -20px;
  }
  .loginRightSubBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 192%;
    max-width: 700px;
    background: white;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    margin: 70px;
  }
}
