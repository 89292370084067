@import "../../../Assets/colors.css";

.addCardLayoutBox {
  width: 100%;
  /* height:77vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin: 20px 0px;
  padding: 0px 15%;
}
.stepperMainBox {
  width: 100%;
  min-width: 1100px;
}
.stepperLabelText {
  font-size: 14px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.activeStepperLabelText {
  font-size: 14px;
  color: var(--lightPurpleColor) !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.LayoutMainBox {
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 15px;
  /* min-width: 1100px; */
  padding: 0px 10px;
}
.mainLayoutPaper {
  /* position: absolute; */
  width: 100%;
  /* height: 77vh; */
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  transform: translateZ(0);
  background-color: var(--whiteColor);
  border-radius: 10px;
}
.mainPaperLayoutSub {
  width: 100%;
  /* height:130vh; */
  overflow: scroll;
  border-radius: 10px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.mainPaperLayoutSub::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.mainPaperLayoutSub {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.firstLayoutStepBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  height: max-content;
  margin-top: 80px;
  margin-bottom: 80px;
}

.layoutHeader {
  position: fixed;
  display: flex;
  gap: 8px;
  align-items: center;
  background-color: var(--whiteColor) !important;
  width: 100%;
  border-bottom: 2px solid var(--AccentColor);
  padding: 15px 10px;
  z-index: 1000;
  margin-top: -1px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.purpleDiv {
  width: 4px;
  height: 20px;
  background-color: black;
  border-radius: 5px;
}
.layoutHeadingText {
  font-size: 20px;
  font-weight: bold;
  color: var(--blackColor);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.bgPatternMainBox {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  width: 100%;
  min-height: max-content;
}
.bgPatternHeading {
  font-size: 14px;
  /* font-weight: bold; */
  color: var(--blackColor);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.bgPatternBoxes {
  display: flex;
  gap: 25px;
  width: 100%;
}
.bgPatternLayouts {
  width: 130px;
  height: 100px;
  box-shadow: 0px 2px 5px 1px;
  border-radius: 15px;
}
.patternLayoutLabel {
  font-size: 14px;
  color: var(--blackColor);
  text-align: center;
  margin-top: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.layoutDivider {
  width: 100%;
  margin: 10px;
}
.addVirtualCardimgBg {
  /* border: 1px solid;*/
  border-radius: 5px;
  /* uncomment this padding when image selecting method will be uncommented */
  /* padding: 5px 5px 0px 5px; */
}
.addvirtualImg {
  width: 100%;
  max-width: 100px;
  border-radius: 10px;
  height: 80px;
  border: 1px solid var(--disableGray);
  overflow: hidden;
}
.virtualImg {
  width: 100%;
  max-width: 100px;
  border-radius: 10px;
  height: 80px;
}
.virtualImgBtnBox {
  /* background-color: var(--whiteColor); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.colorBox {
  padding: 2px;
  border-radius: 3px;
}
.colorBorder {
  border: 1px solid var(--disableGray);
}
.filledColors {
  height: 30px;
  width: 30px;
  border-radius: 2px;
}
.selectedFilledColor {
  height: 30px;
  width: 30px;
  border-radius: 2px;
  background-image: url("/src/Assets/Images/Icons/check.png");
  background-size: 15px;
  background-position: center;
  background-repeat: no-repeat;
}
.uploadImgLogobox {
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content:space-between; */
  gap: 15px;
  padding: 15px;
}
.uploadImgMainBox {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.profilePhotoUploadBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 350px;
  min-width: 350px;
  min-height: 110px;
  max-height: 170px;
  border: 1px dashed var(--lightPurpleColor);
  padding: 5px 0px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  background-color: var(--lightFadedPurpleColor);
  overflow: hidden;
}

.uploadCloudImg {
  background-color: var(--fadedBlueColor);
  max-width: 40px;
  border-radius: 20px;
  padding: 7px;
}
.profilePhotoLayouts {
  width: 95%;
  height: 160px;
}
.imgLabel {
  font-size: 14px;
  color: var(--blackColor);
  text-align: center;
  margin-top: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.layoutfooter {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 2px solid var(--AccentColor);
  padding: 10px 25px;
  margin-bottom: -1px;
  margin-top: auto;
  background-color: var(--whiteColor);
  z-index: 1000;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.nextLayoutBtn {
  width: 100%;
  max-width: max-content;
  display: flex;
  gap: 5px;
  padding: 5px 15px;
  background-color: black !important;
  color: var(--whiteColor) !important;
  text-transform: none;
  margin-left: auto;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.disabledNexyBtn {
  width: 100%;
  max-width: max-content;
  display: flex;
  gap: 5px;
  padding: 5px 15px;
  background-color: var(--feddedLightPurpleColor) !important;
  color: var(--whiteColor) !important;
  text-transform: none;
  margin-left: auto;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.backLayoutBtn {
  display: flex;
  padding: 5px 15px;
  gap: 5px;
  border: 1px solid black;
  color: black;
  text-transform: none;
}
.mainCardLayoutbox {
  width: 100%;
  height: 79vh;
  min-width: 300px;
  max-width: 300px;
  border-radius: 5px;
  padding: 15px;
}
.mainCardLayoutbox1 {
  width: 100%;
  /* height:77vh; */
  min-width: 300px;
  max-width: 300px;
  border-radius: 5px;
  padding: 15px;
}
.cardLayoutBox {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  border: 1px solid;
  overflow: scroll;
}
.cardLayoutBox::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.cardLayoutBox {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.cardLayoutLogo {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: end;
  margin-left: -10px;
}
.cardLogo {
  position: absolute;
  top: 40px;
  left: 25%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.cardLayoutImg {
  width: 100%;
}

.yourDetailsMainbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 80px;
}

.detailsFilleBox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* align-items: start;
    justify-content: start; */
  /* flex-wrap: wrap; */
  gap: 10px;
  padding: 25px;
  margin-top: 25px;
}
.detailsFilleBox1 {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* align-items: start;
    justify-content: start; */
  /* flex-wrap: wrap; */
  gap: 10px;
  margin-top: 25px;
  margin-bottom: 40px;
  padding: 25px;
}
.headingFont {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.alertText {
  font-size: 12.5px;
  color: var(--alertTextColor);
}
.detailsLabelInputBox {
  width: 100%;
  padding: 5px;
}
.detailsLabelInputBox1 {
  width: 100%;
  padding: 5px;
  margin-top: 10px;
}
.detailsInputField {
  width: 100%;
  max-width: 500px;
}

.cardInfoBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cardInfoName {
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  word-break: break-all;
}
.cardInfocmpany {
  font-size: 12px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  word-break: break-all;
}
.cardSocialInfoBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 7px;
}
.cardSocialField {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 5px;
}
.cardSocialIcons {
  width: 100%;
  max-width: 30px;
  /* background-color: var(--dynamicGreen); */
  padding: 5px;
  border-radius: 24px;
}
.cardSocialInfoText {
  font-size: 12px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  word-break: break-all;
  margin-left: 5px;
}
.socialMediaMainBox {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border: 1px solid var(--disableGray);
  padding: 5px;
  border-radius: 5px;
}
.socialMediaheaderBox {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 0px 0px;
  height: 35px;
}
.scoialImg {
  width: 35px;
  height: 35px;
  margin: auto 0px auto 0px;
}
.socialMediaheadrTitle {
  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  word-break: break-all;
  margin-left: 5px;
}
.socialMediaheadrTitle1 {
  font-size: 12px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  word-break: break-all;
  margin-left: 5px;
}

.phoneNumberInputBox {
  /* width: 100%;
  display: flex;
  border: 1px solid var(--disableGray);
  height: 40px;
  border-radius: 5px; */
}
.phoneNumberSelectionBox {
  width: 100%;
  max-width: 85px;
  margin-top: 5px;
  margin-left: 5px;
}
.phoneNumberSelectionImg {
  margin-right: 5px;
  width: 25px;
}
.phoneNumberDivider {
  /* border: 1px solid var(--disableGray);
  height: 30px;
  margin-top: 3px; */
}
.phoneNumberfilled {
  width: 100%;
  border-radius: 4px;
  font-family: FontMedium;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--whiteColor);
  background-color: var(--whiteColor) !important;
}

.socialInputadornment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--lightblueColor);
  color: var(--fadedBlack);
  height: 38px;
  width: 40px;
  margin-left: -13px;
  margin-right: 5px;
}
.cardPreviewBox {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.cardPreviewText {
  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  word-break: break-all;
  font-weight: bold;
}
@media screen and (min-height: 850px) {
  /* .firstLayoutStepBox{
            height: 100vh;
    } */
}
@media screen and (max-height: 700px) {
  /* .yourDetailsMainbox{
            min-height: 90vh;    
    } */
  /* .mainCardLayoutbox{ 
        height: max-content;
    } */
  .mainLayoutPaper {
    min-height: 100vh;
  }
}

@media screen and (max-width: 1310px) {
  .addCardLayoutBox {
    padding: 0px 1%;
  }
  .stepperMainBox {
    min-width: 100%;
  }
  .mainLayoutPaper {
    width: 50vw;
    /* height: 77vh; */
  }
  .LayoutMainBox {
    min-width: 100%;
  }
  .mainCardLayoutbox {
    max-width: 300px;
    min-width: 250px;
  }
  .firstLayoutStepBox {
    width: 65vw;
  }
  .detailsFilleBox1 {
    grid-template-columns: 1fr;
  }
  .uploadImgLogobox {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
  }
}

@media screen and (max-width: 1024px) {
  .bgPatternLayouts {
    width: 12vw;
    height: 100px;
  }
  .mainCardLayoutbox {
    height: 100vh;
  }

  /* .profilePhotoUploadBox{
        width: 100%;
        max-width: 210px;
        min-width: 210px;
        min-height: 100px;
        max-height: 150px;
        } */
}
@media screen and (max-width: 890px) {
  .mainCardLayoutbox {
    height: max-content;
    min-height: max-content;
  }
  .cardLayoutBox {
    height: max-content;
  }
  .bgPatternLayouts {
    width: 15vw;
    min-width: 115px;
  }
  .LayoutMainBox {
    width: 100%;
    flex-wrap: wrap-reverse;
    min-width: 100%;
  }
  .addCardLayoutBox {
    padding: 0px 2%;
  }
  .stepperMainBox {
    min-width: 100%;
  }
  .mainLayoutPaper {
    width: 85vw;
    /* height: 77vh; */
  }
  .mainCardLayoutbox {
    max-width: 300px;
    min-width: 250px;
  }
  .firstLayoutStepBox {
    width: 100vw;
  }
  .detailsFilleBox1 {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 600px) {
  .detailsFilleBox {
    grid-template-columns: 1fr;
  }
  .detailsFilleBox1 {
    grid-template-columns: 1fr;
  }
  .uploadImgLogobox {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
  }
  .profilePhotoUploadBox {
    max-width: 350px;
    min-width: 350px;
    min-height: 110px;
    max-height: 170px;
  }
  .bgPatternLayouts {
    width: 19vw;
    min-width: 80px;
  }
  /* .logoImageCommonbox{
            margin: auto auto auto 9%;
        } */
  .firstLayoutStepBox {
    width: 100vw;
  }
  .cardLayoutBox {
    min-height: 250px;
  }
  .cardSocialInfoText {
    margin-left: 5px;
  }
}
@media screen and (max-width: 450px) {
  .mainCardLayoutbox1 {
    min-width: 250px;
    max-width: 300px;
    height: max-content;
    min-height: max-content;
  }
  .bgPatternBoxes {
    gap: 5px;
  }
  .bgPatternLayouts {
    width: 20vw;
    height: 70px;
  }
  .addvirtualImg {
    max-width: 70px;
    height: 50px;
  }
  .virtualImg {
    max-width: 70px;
    height: 50px;
    margin-left: 5px;
  }
  .profilePhotoUploadBox {
    max-width: 250px;
    min-width: 250px;
  }
  .mainLayoutPaper {
    margin-top: 5px;
  }
}
