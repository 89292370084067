@import "../../Assets/colors.css";


.scannerPageMainBox {
    display: flex;
    flex-direction: flex;
    /* align-items: center; */
    width: 100%;
    /* justify-content: flex-start; */
    /* background-color: var(--loginBg); */
    /* min-height: 100vh; */
    height: 100vh;
    /* gap: 25px; */
    overflow: hidden;
}

.scannerPagelayout {
    width: 100%;
    padding-bottom: 30px;
}

.scannerPageShowBox {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px 150px 10px;
}