@import "../../Assets/colors.css";

.cardPagemainBox {
    display: flex;
    flex-direction: flex;
    /* align-items: center; */
    width: 100%;
    /* justify-content: flex-start; */
    background-color: var(--loginBg);
    min-height: 100vh;
    height: 100%;
    /* gap: 25px; */
    overflow: hidden;
}
.cardPagelayout{
  width: 100%;
  /* overflow: hidden; */
  /* height: 100%; */
  /* height: 100%; */
  /* width: 80%; */
  /* margin-top: 10px; */
  /* padding-right: 10px; */
  /* margin-left: 130px; */
}
.cardLayoutShowBox{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 55px;
}
.cardPageBox{
    /* display: flex;
    align-items: center;
    justify-content: center; */
    width: 100%;
    max-width: max-content;
    /* max-width: 280px;
    min-height: 280px; */
    border-radius: 10px;
    box-shadow: 0px 0px 10px 5px var(--shadowGrayColor2);
    /* background-color: var(--MainColor); */
    /* padding: 15px; */
    /* border: 1px solid; */
    /* cursor: pointer; */
}

.cardPageLayoutBox{ 
    width: 100%;
    height:max-content;
    min-width: 380px;
    max-width: 380px;
    border-radius: 5px;
    padding: 15px;
}

.cardPageLayoutLogo{ 
    position: relative;
    width: 100%;
    display: flex;
    justify-content: end; 
    margin-left: -10px;
   } 

.cardLayoutBox{ 
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
    height:100%;
    border-radius: 15px;
    border: 1px solid;
    overflow: scroll;
}

.cardLayoutBox::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .cardLayoutBox {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .cardPageLogo{
    position: absolute;
top: 40px;
left: 25%;
transform: translate(-50%, -50%);
width: 60px;
height: 60px;
border-radius: 24px;
}
.cardPageInfoBox{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}
.cardPageSocialInfoBox{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 7px;
}
.cardPageInfoName{
    font-size: 18px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    word-break: break-all;
}
.cardPageInfocmpany{
    font-size: 12px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    word-break: break-all;
}
.cardPageSocialField{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 5px;
}
.cardPageSocialIcons{
    width:100%;
    max-width: 30px;
    /* background-color: var(--dynamicGreen); */
    padding: 5px;
    border-radius: 24px;
}
.cardPageSocialInfoText{
    font-size: 12px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    word-break: break-all;
    margin-left: 5px;
}

.cratecardBtnBox{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 15px 15px;
    margin-top: 8px;
    border-top: 1px solid var(--dividercolor);
}

.createCardBtn {
    font-size: 16px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: var(--lightPurpleColor) !important;
    color: var(--whiteColor);
    text-transform: none;
    margin-left: auto;
}

@media screen and (max-height: 750px) {
    .cardPageLayoutBox{ 
        height: max-content;
        /* height:100vh;
        max-height: max-content; */
    }
}
@media screen and (max-width: 450px) {
    .cardPageLayoutBox{
        min-width: 330px;
        max-width: 100%;
    }
}

@media screen and (max-width: 350px) {
    .cardPageLayoutBox{
        min-width: 280px;
    }
}
@media screen and (max-width: 300px) {
    .cardPageLayoutBox{
        min-width: 100%;
    }
}