@import "../../../Assets/colors.css";

.headerBarBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f7f7f7;
  padding: 5px 5px;
}

.headerLogoImg {
  width: 100%;
  max-width: 65px;
}

.headerPannelsBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  gap: 5%;
}

.drawerBtn {
  color: var(--fadedBlack);
}

/* .pannelBtns{
    text-transform: none;
    color: var(--whiteColor);
    font-size: 16px;
    background-color: transparent !important;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.selectedPannelBtns{
    text-transform: none;
    color: var(--whiteColor);
    font-size: 16px;
    background-color: var(--whiteFadedColor) !important;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
} */

.width_100 {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 90%;
  background-color: white; /* Light background for visibility */
  margin-left: 15px;
  margin-top: 5px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2); /* Increased shadow for visibility */
  transition: all 0.3s ease-in-out;
}
.width_100:hover {
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.4);
  transform: translateY(-2px); /* Slight lift effect */
  background-color: #f7f7f7 !important;
}

.headerAddBtn {
  width: 100%;
  max-width: fit-content;
  color: var(--fadedBlack);
  text-transform: none;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  /* background-color: var(--lightPurpleColor) !important; */
  font-size: 16px;
  padding: 5px 15px;
}

.headerAddBtn1 {
  width: 50%;
  color: white;
  background: linear-gradient(180deg, #be6639, #494445) !important;
  /* border-radius: 5%; */
  padding: 10px;
  margin: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  font-size: larger;
  font-weight: bold;
  border: none;
  border-radius: 5px;
}
.headerAddBtn1:hover {
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.4);
  transform: translateY(-2px); /* Slight lift effect */
  background-color: black !important;
}

.css-qwbig-MuiSvgIcon-root {
  background-color: transparent;
  margin-bottom: -4px;
  margin-right: 2px;
  font-weight: 800;
}

.searchBarHeader {
  width: 100%;
  max-width: 350px;
}

.clickable {
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .headingDrawer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 250px;
    height: 100vh;
    padding: 8px 0px;
  }

  .drawerListBox {
    width: 100%;
  }

  .headerPannelsBox {
    gap: 1%;
  }

  .headerBarBox {
    /* justify-content: space-around; */
    background-color: #f7f7f7;
    padding: 5px 5px;
  }

  .drawerLogoutBtn {
    margin-top: auto;
    margin-right: auto;
    text-transform: none;
    color: var(--fadedBlack);
    font-size: 16px;
    background-color: transparent !important;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }

  .logoutBtnImg {
    width: 100%;
    max-width: 15px;
  }
}

.headerLogoMobileBox {
  padding-top: 10px;
  width: 100%;
  margin-right: auto;
}

.headerLogoMobile {
  width: 20%;
  margin-left: 10px;
}

@media screen and (max-width: 700px) {
  .headerLogoImg {
    width: 100%;
    max-width: 90px;
  }

  .headerBarBox {
    justify-content: space-between;
    padding: 5px 0px;
    background-color: #f7f7f7;
  }

  /* .drawerLogoutBtn{
        margin-top: auto;
        margin-right: auto;
        text-transform: none;
        color: var(--blackColor);
        font-size: 16px;
        background-color: transparent !important;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
    .logoutBtnImg{
        width: 100%;
        max-width: 25px;
    } */
}
@media screen and (max-width: 1200px) {
  .headerLogoImg {
    width: 100%;
    max-width: 90px;
  }

  .headerBarBox {
    justify-content: space-between;
    padding: 5px 0px;
    background-color: #f7f7f7;
  }
}

@media (min-width: 1024px) {
  .headerLogoImg {
    width: 50%;
  }
}
