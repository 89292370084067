:root {
  --MainColor: #7BC6CC;
  --AccentColor: #E0E4E5;
  --HighlightColor: #FFFFFF;
  --SecondaryAccent: #A8DADC;
  --blackColor: #000;
  --hashBorder: #e6dddd;
  --whiteColor: #ffffff;
  --alertTextColor: #fa2828b5;
  --imageBtnColor: #c2c2c2;
  --shadowGrayColor:#D7D7D7;
  --disableGray:#aaaaaa;
  --shadowGrayColor2: #BCBCBC;
  --whiteFadedColor: #ffffff1d;
  --darkNavyBlue: #011626;
  --blueColor: #0062ff;
  --fadedBlueColor: #0062ff10;
  --lightPurpleColor: #3E64FF;
  --feddedLightPurpleColor: #3e65ff8b;
  --lightFadedPurpleColor: #F5F7FA;
  --dynamicGreen:#5BB74C;
  --loginBg: #F5F7FF;
  --fadedBlack: #616161;
  --redColor:#F55757;
  --dividercolor: #DBDBDB;
  --lightblueColor:#E2EBF3;

}