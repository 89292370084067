@import "../../../Assets/colors.css";

.nameFieldBox {
    width: 100%;
}

.MuiInputLabel-root {
    font-size: 18px;
    right: 0;
    text-align: center;
    color: var(--blackColor) !important;
}