.settingMainBox{
    width: 100%;
    min-height: 80vh;
}

.settingHeading{
    font-size: 25px;
    border-bottom: 1px solid;
}
.contentBox{
    margin-top: 10px;
}
.settingProfileBox{
    background-color: var(--whiteColor);
    width: 100%;
    max-width: 500px;
    box-shadow: 5px 5px 10px 0px ;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    border-radius: 15px;
}
.profileDetails{
    display: flex;
    gap: 10px;
}
.editProfileDialog{
   /* width: 250px; */
   padding: 20px;
}
.editAvatar{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}
.editName{
    width: 100%;
    margin-top: 15px;
}
.editNameInput{
    width: 100%;
    margin-top: 15px;
    background-color: var(--whiteColor);
    padding: 0px;
}
.btnBox{
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: end;
    margin-top: 15px;
}
.settingAccountBox{
    background-color: var(--whiteColor);
    width: 100%;
    max-width: 500px;
    box-shadow: 5px 5px 10px 0px ;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    border-radius: 15px;
     margin-top: 10px;
}