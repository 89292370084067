/* @import "../../../Assets/colors.css";

.whiteBox {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    background-color: var(--HighlightColor);
    padding: 40px 70px 55px 70px;
    width: 100%;
    height: 100%;
    max-width: 500px;
    max-height: 460px;
    gap: 15px;
    margin: auto;
}

.whiteBoxTitleBlack {
    font-size: 18px;
    color: var(--blackColor);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.whiteBoxTitleMain {
    font-size: 18px;

    color: #71CBE5;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.page2NextBtn {
    width: 100%;
    background-color: #71CBE5 !important;
    text-transform: none;
    color: var(--HighlightColor);
    border-radius: 0px;
    max-width: max-content;
    margin: 8% auto 0 auto;
    padding: 10px 25px;
}

.whiteBoxBtnTxt {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

@media screen and (max-width:450px) {
    .whiteBox {
        padding: 20px 40px 30px 40px;
    }
} */

@import "../../../Assets/colors.css";

.whiteBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: var(--HighlightColor);
  padding: 40px 70px 55px 70px;
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 460px;
  gap: 15px;
  margin: auto;
  border-radius: 12px;
  /* Added rounded corners for a smoother look */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  /* Initial soft shadow for 3D effect */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* Smooth transitions */
}

/* Add hover effect for the .whiteBox to give depth */
.whiteBox:hover {
  transform: translateY(-6px);
  /* Lift the box on hover */
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  /* Stronger shadow for depth */
}

/* White box title - Black */
.whiteBoxTitleBlack {
  font-size: 18px;
  color: var(--blackColor);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

/* White box title - Main */
.whiteBoxTitleMain {
  font-size: 19px;
  color: black;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.page2NextBtn {
  width: 100%;
  background: linear-gradient(180deg, #be6639, #494445);
  text-transform: none;
  color: var(--HighlightColor);
  border-radius: 8px;
  /* Slight rounding for a modern look */
  max-width: max-content;
  margin: 8% auto 0 auto;
  padding: 10px 25px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  /* Smooth transition for hover and active effects */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  /* Initial shadow for 3D effect */
  transform: translateY(0);
  /* Initial position */
}

/* Hover state for elevating the button */
.page2NextBtn:hover {
  transform: translateY(-6px);
  /* Lift button on hover */
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.3);
  /* Stronger shadow for 3D effect */
}

/* Active state for a pressed button effect */
.page2NextBtn:active {
  transform: translateY(4px);
  /* Push button down on click */
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  /* Smaller shadow when clicked */
}

/* Text within the whiteBox button */
.whiteBoxBtnTxt {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

@media screen and (max-width: 450px) {
  .whiteBox {
    padding: 20px 40px 30px 40px;
  }
}
