@import "../../Assets/colors.css";

.ScannerBox {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
}

.cameraBtn {
  text-transform: none;
}

.cameraTitle {
  color: var(--blackColor);
  background-color: transparent !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.css-1l9sosn-MuiButtonBase-root-MuiButton-root {
  background-color: transparent !important;
  color: black !important;
  border: 1px solid black !important;
  box-shadow: rgba(23, 23, 24, 0.2) 0px 4px 8px !important;
}

.imageMainBox {
  width: 100%;
  max-width: 360px;
  height: 232px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.scannerImage {
  width: 100%;
  max-width: 232px;
}

.scannerImageCloseIconBtn {
  position: absolute;
  margin-left: 335px;
  margin-top: -6px;
}

.closeIcon {
  font-size: 19px;
}

.galleryOption {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  /* color: #e1aa86;*/
  color: #71cbe5;
  font-size: 14px;
}

.galleryOption:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.optionsBox {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.cameraOption,
.galleryOption {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  color: #333;
  text-transform: none;
  border: 1px solid #ccc;
  padding: 8px 16px;
  border-radius: 8px;
}

.cameraOption:hover,
.galleryOption:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

/* Add this in your CSS file */
.loadingIndicator {
  margin: 20px 0;
  text-align: center;
}

.scannerImage {
  width: 100%;
  height: auto;
}

.optionsBox {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.cameraOption,
.galleryOption {
  margin: 10px;
}
