.displayCardBox {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  gap: 20px;
  padding: 20px;
}

.newCardBox {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 0px 0px 10px 2px var(--shadowGrayColor2);
  border-radius: 12px;
  width: 100%;
  max-width: 350px;
  overflow: hidden;
  margin: 0 auto;
  background-color: white !important;
  padding: 20px 15px;
}

.cardHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}

.companyName {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: var(--blackColor);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.moreOptionsBtn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  background-color: white !important;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.newCardImg {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 8px;
  margin: 10px 0;
}

.cardContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.cardDetailsLeft {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
}

.detailItem {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: var(--fadedBlack);
}

.detailItem svg {
  color: var(--blackColor);
}

.cardDetailsRight {
  display: flex;
  align-items: flex-start;
}

.companyLogo {
  width: 50px;
  height: 50px;
  object-fit: contain;
  border-radius: 8px;
}

.emptycardBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 5px;
}

.emptycardImg {
  width: 80%;
  max-width: 400px;
  margin-bottom: 20px;
}

.emptycardText {
  color: var(--blackColor);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 21px;
}

.emptycardText2 {
  color: var(--blackColor);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.allNewCardBox {
  width: 100%;
  display: flex;
  gap: 20px;
}

@media screen and (max-width: 1024px) {
  .displayCardBox {
    flex-wrap: wrap;
  }

  .allNewCardBox {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .newCardBox {
    margin: auto;
  }
}

@media screen and (max-width: 600px) {
  .newCardLogo {
    width: 28%;
  }

  .detailItem {
    font-size: 12px;
  }
}

@media screen and (max-width: 350px) {
  .allNewCardBox {
    display: flex;
    flex-wrap: wrap;
  }

  .newCardLogo {
    max-width: 25%;
  }

  .emptycardText {
    font-size: 15px;
  }

  .emptycardText2 {
    font-size: 12px;
  }

  .displayCardBox {
    padding: -10px;
  }
}

.qrCodeModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.qrCodeContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
}

.qrCodeFooter {
  margin-top: 20px;
  width: 100%;
  text-align: center;
}

.qrCodeFooter button {
  padding: 10px 20px;
  border: none;
  background: black;
  color: black;
  cursor: pointer;
  border-radius: 4px;
}

.qrCodeContent h2 {
  margin-bottom: 20px;
}

.qrCodeContent canvas {
  margin-bottom: 20px;
}
