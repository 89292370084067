@import "../../../Assets/colors.css";

.sideBarBox {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-between; */
  gap: 25px;
  /* padding: 25px; */
  /* height: 100vh; */
  border: 1px solid var(--dividercolor);
  /* position: fixed; */
}

.logoBoxsidebar {
  width: 100%;
  padding: 15px 25px 8px 25px;
  border-bottom: 1px solid var(--dividercolor);
  transform: translateX(0);
  transition: all 0.15s ease-in;
  /* -webkit-transition: 500ms width ease-in;  */
}

.closedlogoBoxsidebar {
  width: 100%;
  padding: 15px 8px 8px 15px;
  /* border-bottom: 1px solid var(--dividercolor); */
  transform: translateX(0);
  transition: all 0.15s ease-out;
  /* -webkit-transition: 500ms width ease-in;  */
}

.optionMainBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  height: 100%;
  gap: 10px;
}

.barButton {
  color: var(--blackColor) !important;
  background-color: transparent !important;
  padding: 0px;
  min-width: 25px;
  max-width: 25px;
}

.barButton2 {
  color: var(--blackColor) !important;
  background-color: transparent !important;
  padding: 0px;
  min-width: 25px;
  max-width: 25px;
}

.pannelBtns {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  width: 100%;
  text-transform: none;
  color: var(--fadedBlack);
  font-size: 16px;
  background-color: transparent !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  /* max-width: max-content;
    min-width: max-content; */
}

.selectedPannelBtns {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  width: 100%;
  text-transform: none;
  color: var(--whiteColor);
  font-size: 16px;
  background-color: var(--lightPurpleColor) !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  /* max-width: max-content;
min-width: max-content; */
}

.barIcon {
  width: 100%;
  height: 100%;
}

.barIcon2 {
  width: 100%;
  max-width: 30px;
  /* height: 30px; */
}

.helpLogoutbox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 10px;
  margin-top: auto;
  padding: 15px 5px 15px 5px;
  border-top: 1px solid var(--dividercolor);
  border-width: 80%;
}

@media screen and (max-width: 1024px) {
  .logoBoxsidebar {
    width: 100%;
    padding: 15px 5px 8px 5px;
    border-bottom: 1px solid var(--dividercolor);
  }
}

@media (min-width: 1024px) {
  .img1 {
    max-width: 100px;
    position: relative;
    left: 20px;
  }
  .helpLogoutbox {
    position: relative;
    bottom: 107px;
    left: 830px;
    border: none;
    display: table-cell;
  }
  .closedlogoBoxsidebar {
    background: white;
    border-radius: 25px;
    padding: 15px 5px 8px 5px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
  }
  .sideBarBox {
    padding: 25px;
  }
  .mobilesearchBarHeader {
    margin-top: -75px;
  }
}
