@import "../../Assets/colors.css";


/* .qrBox {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    padding: 40px 70px 55px 70px;
    width: 100%;
    height: 100%;
    max-width: 550px;
    max-height: 500px;
    gap: 15px;
    margin: auto;
} */


.qrBox{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 2%;
    height: 80%;
    padding: 15px 15px;
}
.qr{
    width: 100%;
    height:70%;
}
.qrLayoutBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 100%;
    max-width: max-content;
    background-color: var(--whiteColor);
    padding: 25px 25px;
    border-radius: 8px;
    font-size: 14px;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    box-shadow: 0px 0px 10px 2px var(--AccentColor);
}
.qrScanText{
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 18px;
}
.qrCode{
    padding: 20px 20px;
    background: var(--whiteColor);
    border: 1px solid var(--AccentColor);
    border-radius: 8px;
}
.qrCurvyBorderBox{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 25px;
    border-radius: 4px;
    max-height: fit-content;
    background:
    linear-gradient(to right, var(--lightPurpleColor) 4px, transparent 4px) 0 0,
    linear-gradient(to right, var(--lightPurpleColor) 4px, transparent 4px) 0 100%,
    linear-gradient(to left, var(--lightPurpleColor) 4px, transparent 4px) 100% 0,
    linear-gradient(to left, var(--lightPurpleColor) 4px, transparent 4px) 100% 100%,
    linear-gradient(to bottom, var(--lightPurpleColor) 4px, transparent 4px) 0 0,
    linear-gradient(to bottom, var(--lightPurpleColor) 4px, transparent 4px) 100% 0,
    linear-gradient(to top, var(--lightPurpleColor) 4px, transparent 4px) 0 100%,
    linear-gradient(to top, var(--lightPurpleColor) 4px, transparent 4px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 25% 25%;
}
.qrShareBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 5px 15px;
    background-color: var(--lightPurpleColor) !important;
    font-size: 14px;
    text-transform: none;
    /* margin-top: 10px; */
    color: var(--whiteColor);
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}
.qrImage {
    width: 23%;
}
.qrShareBtnBox{
    display: flex;
    align-items: center;
    justify-content:center;
    /* margin-top:3%; */
}

@media screen and (max-height:750px) {
    .qrBox{
        height: 90%;
     }
}

@media screen and (max-height:450px) {
     .qrLayoutBox{
        max-width: 300px;
        padding: 20px 20px;
        gap: 10px;
    }
    .qrScanText{
        font-size: 14px;
    }
    .qrCode{
        padding: 15px 15px;
    }
}

@media screen and (max-width: 450px) {
    .qrBox{
        padding: 10px 10px;
    }
    .qrLayoutBox{
        padding: 20px 20px;
    }
    .qrCode{
        padding: 15px 15px;
    }
}

@media screen and (max-width: 350px) {
    .qrScanText{
        font-size: 14px;
    }
}