.signupPage {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #f7f7f7;
}

.signupLogoBox {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.signupPageLogo {
  width: 100%;
  max-width: 100px;
  position: absolute;

  top: 50px;
  right: 328px;
}

.signupPageImg {
  width: 100%;
  max-width: 500px;
  margin: auto;
}
.logoName {
  color: var(--HighlightColor);
  font-size: 18px;
  font-weight: 500;
}

.signupRightSubBox {
  width: 95%;
  display: flex;
  margin-left: 10px !important;
  flex-direction: column;
  gap: 20px;
  margin: 20px;
  background: #f7f7f7 !important;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.signupRightSubBox2 {
  width: 95%;
  display: flex;
  margin-left: 10px !important;
  flex-direction: column;
  gap: 20px;
  margin: 20px;
  background: #f7f7f7 !important;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* .signupRightSubBox {
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); 
  transform: translateY(0);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
} */

.signupBoxHeadingText {
  font-size: 36px;
  color: rgb(216, 20, 20);
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  /* background: linear-gradient(45deg, #c17755, #9a9494); */
  background: linear-gradient(45deg, #be663e, #252525);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.signupBoxTitleText {
  font-size: 16px;
  color: var(--fadedBlack);
  margin-bottom: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.signupInputHeading {
  font-size: 18px;
  letter-spacing: 3px;
  font-weight: 500;
  color: var(--blackColor);
  margin-bottom: 8px;
}

.alertText {
  color: var(--alertTextColor);
  font-size: 12px;
  margin-top: 4px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.signupBtnBox {
  margin-top: 10px;
}

.signupBtn {
  width: 100%;
  height: 40px;
  background: linear-gradient(180deg, #be6639, #494445) !important;
  color: var(--whiteColor) !important;
  text-transform: none !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}

.signupBtn:hover {
  background: linear-gradient(180deg, #be6639, #494445) !important;
}

.googleBtn {
  width: 100%;
  height: 40px;
  background-color: var(--whiteColor) !important;
  color: var(--blackColor) !important;
  border: 1px solid var(--dividercolor) !important;
  text-transform: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 14px !important;
  border-radius: 4px !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.dividerText {
  color: var(--fadedBlack);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 15px 0 !important;
}

.haveAccountText {
  text-align: center;
  color: var(--fadedBlack);
  font-size: 14px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.loginText {
  color: var(--lightPurpleColor);
  margin-left: 5px;
  cursor: pointer;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

@media (max-width: 900px) {
  .signupPage {
    flex-direction: column;
  }

  .signupLogoBox {
    width: 100%;
    height: auto;
    padding: 30px 0;
  }

  .signupPageLogo {
    position: static;
  }

  .signupRightBox {
    width: 100%;
    background-color: #f7f7f7;
    height: auto;
  }

  .signupRightSubBox {
    max-width: 100%;
  }
  .signupRightSubBox2 {
    max-width: 100%;
  }
}

.css-ajo3yr {
  justify-content: center !important;
}

@media (min-width: 1024px) {
  .signupPageImg {
    display: none;
  }
  .signupPageLogo {
    width: 131%;
    max-width: 198px;
    position: absolute;
    top: 62px;
    right: 500px;
  }
  .signupPage {
    display: block;
    width: 49%;
    height: 37vh;
    margin-top: 240px;
    background-color: #f7f7f7;
  }
  .signupRightSubBox {
    width: 105%;
    margin-left: 34px;
    position: relative;
    left: 280px;
  }

  .signupRightSubBox2 {
    width: 105%;
    margin-left: 34px;
    position: relative;
    left: 280px;
    bottom: 900px;
  }
}
