.c-cardMainBox {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.c-MainCard {
  border-radius: 15px;
  width: 360px;
  padding: 10px;
  background-color: white;
}
.c-cardMainBox {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
  background-color: #6f6f78; /* Transparent Background */
  backdrop-filter: blur(10px); /* Optional glass effect */
}

.c-MainCard {
  width: 320px;
  border-radius: 16px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  background: white;
  transition: transform 0.3s;
}

.c-MainCard:hover {
  transform: scale(1.03);
}

.c-cardHeader {
  position: relative;
  padding: 16px;
  text-align: center;
}

.c-cardEditIcon {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: #6c63ff;
  background: #f0f0ff;
  border-radius: 50%;
  padding: 0;
  transition: all 0.3s;
}

.c-cardEditIcon:hover {
  background: #ecebff;
}

.c-icon {
  font-size: 2rem;
  padding: 5px;
}

.c-cardImgBox {
  width: 70%;
  height: auto;
  margin: 0 auto;
  border-radius: 12px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-cardImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 4px solid #fff;
}

.c-cardTitle {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}

.c-cardHeadline {
  font-size: 14px;
  color: #757575;
  text-align: center;
  margin-bottom: 16px;
}

.c-cardAccordion {
  box-shadow: none;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 16px;
}

.c-accordionTitle {
  font-size: 16px;
  font-weight: bold;
}
