.confirmModalBox{
    min-width: 60vh;
    width: 100%;
    padding: 10%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.modalHeadingText{
    font-size: 21px;
}
.submitBtnsBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10%;
}

.confirmBtn{
    width: 100%;
    background-color: var(--alertTextColor) !important;
    color: var(--whiteColor);
    text-transform: none;
    font-size: 18px;
}
.cancelBtn{
    width: 100%;
    background-color: var(--MainColor) !important;
    color: var(--blackColor);
    text-transform: none;
    font-size: 18px;
}