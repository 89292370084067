@import "../../Assets/colors.css";

/* 🔹 Main Container */
.mainLoginBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* min-height: 100vh; */
  background-color: var(--newLoginBg);
  padding: 0px;
}

/* 🔹 Layout */
/* .layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px;
} */

.layout {
  display: grid;
  flex-direction: column;
  width: 100%;
  padding: 0px;
}

/* 🔸 Dashboard Footer */
.dashboardCardFooterbox {
  margin-top: auto;
  display: flex;

  align-items: center;
  justify-content: end;
  width: 100%;
  border-top: 1px solid var(--newAccentColor);
  background-color: var(--newWhiteColor);
  padding: 15px;
}

/* 🎨 3D Modern Buttons */
.dashboardCardEditBtn {
  color: white !important;
  background: linear-gradient(180deg, #be6639, #494445) !important;
  /* Updated color */
  background: linear-gradient(
    145deg,
    var(--newLightPurpleColor),
    var(--newDarkPurpleColor)
  );
  /* Updated color */
  font-size: 16px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif !important;
  font-weight: 600 !important;
  padding: 12px 16px;
  border-radius: 10px;
  border: none;
  box-shadow: 3px 3px 8px rgba(28, 28, 28, 0.2),
    -3px -3px 8px rgba(46, 46, 46, 0.3);
  transition: all 0.2s ease-in-out;
}

.dashboardCardEditBtn:hover {
  transform: translateY(-2px);
  box-shadow: 4px 4px 10px rgba(49, 49, 49, 0.3),
    -4px -4px 10px rgba(52, 51, 51, 0.3);
}

.dashboardCardEditBtn:active {
  transform: scale(0.96);
  box-shadow: inset 3px 3px 8px rgba(0, 0, 0, 0.2),
    inset -3px -3px 8px rgba(78, 77, 77, 0.3);
}

.MuiTab-root:not(.showContactPage) {
  background: linear-gradient(180deg, #be6639, #494445);
  color: white; /* Text color to stand out */
  border-radius: 8px; /* Rounded corners for smooth look */
  padding: 8px 16px; /* Better spacing */
  transition: all 0.3s ease-in-out;
}

/* Hover Effect */
.MuiTab-root:not(.showContactPage):hover {
  background: linear-gradient(180deg, #be6639, #494445);
  box-shadow: 3px 6px 12px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}

/* Active Tab Effect */
.MuiTab-root.Mui-selected {
  /* background: linear-gradient(120deg, #be663e, #2e2b2b); */
  background: linear-gradient(180deg, #be6639, #494445);
  font-weight: bold;
  box-shadow: 4px 8px 14px rgba(0, 0, 0, 0.4);
}

.css-2ehmn7-MuiInputBase-root-MuiOutlinedInput-root {
  display: flex !important;
}

/* 🔍 Stylish Search Box */
.mobilesearchBarHeader {
  /* width: 100%; */
  /* max-width: 500px; */
  display: inline-block;
  align-items: center;
  background: rgba(255, 255, 255, 0.3);
  /* Glassmorphism */
  backdrop-filter: blur(10px);
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.3);
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 4px 0px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  /* padding-right: 100px; */
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 16px;
}

.mobilesearchBarHeader input {
  width: 2000%;
  border: none;
  outline: none;
  background: transparent;
  font-size: 16px;
  color: var(--newTextColor);
  /* Updated color */
  padding-right: 0px !important;
  /* Add padding to make space for the icon */
}

/* Adjusted Search Icon */
.mobilesearchBarHeader input::placeholder {
  color: var(--newPlaceholderColor);
  /* Updated color */
}

.mobilesearchBarHeader:focus-within {
  border: 1px solid var(--newPrimaryColor);
  /* Updated color */
  box-shadow: 0px 4px 15px rgba(63, 62, 64, 0.3);
}

/* 🔳 3D Cards */
.yourcards {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(145deg, #3b3939, #e6e6e6);
  border-radius: 12px;
  padding: 15px;
  max-width: 400px;
  border: none;
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.15),
    -6px -6px 12px rgba(79, 76, 76, 0.7);
  transition: all 0.3s ease-in-out;
}

.yourcards:hover {
  transform: translateY(-3px);
  box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.2),
    -8px -8px 15px rgba(64, 64, 64, 0.7);
}

.yourcards h1 {
  font-size: 1.9rem;
  color: var(--newCardTitleColor);
  /* Updated color */
  font-family: "Arial", sans-serif;
  text-transform: capitalize;
}

/* 📱 Responsive Tweaks */
@media (max-width: 600px) {
  .mobilesearchBarHeader {
    max-width: 100%;
    width: 90%;
  }

  .yourcards {
    max-width: 90%;
  }
}

/* Flex container for Tabs */
.css-98yc81-MuiTabs-root {
  overflow: hidden;
  min-height: 48px;
  display: contents !important;
  width: 59%;
  border-radius: 12px;
  background-color: #d55353;
}

element.style {
  overflow: hidden;
  /* margin-bottom: 92px; */
  background-color: white !important;
}

.css-heg063-MuiTabs-flexContainer {
  display: inline-flex !important;
  justify-content: center;
  gap: 10px;
  margin-left: 10px;
  width: 95% !important;
  /* background-color: #000 !important; */
}

/* Styling each Tab */
.MuiTab-root {
  flex: 1;
  /* Allow each tab to take equal width */
  background-color: rgb(111, 111, 120);
  color: rgb(0, 0, 0);
  font-weight: bold;
  margin: 5px;
  padding: 12px;
  border-radius: 12px;
  transition: 0.3s;

  /* 3D shadow */
  transform: translateY(0);
  /* Initial position */
  display: flex;
  align-items: center;
}

/* Hover effect for tabs */
.MuiTab-root:hover {
  box-shadow: 0 8px 16px rgba(111, 111, 120, 0.3),
    0 -8px 16px rgba(111, 111, 120, 0.3);
  /* Hover shadow */
  transform: translateY(-4px);
  /* Elevation on hover */
  background-color: black;
}

/* Selected Tab styling */
.Mui-selected {
  color: white;
  /* Ensure selected text is visible */
  box-shadow: 0 8px 16px rgba(111, 111, 120, 0.3),
    0 -8px 16px rgba(111, 111, 120, 0.3);
  /* 3D shadow for selected tab */
}

/* Dynamic background color for the contact tab */
.MuiTab-root.showContactPage {
  background-color: black !important;
  color: white;
}

/* Non-contact tab styling */
.MuiTab-root:not(.showContactPage) {
  background-color: black;
  /* color: rgb(255, 255, 255); */
  color: white;
}
.css-1aquho2-MuiTabs-indicator {
  position: absolute;
  height: 2px;
  bottom: 0;
  width: 100%;
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: rgb(90, 56, 56);
}

@media (max-width: 4000px) {
  .sideBarBox {
    flex-direction: row !important;
    border: none !important;
    align-items: center !important;
    display: inline-block !important;
  }
  .closedlogoBoxsidebar {
    border: none !important;
  }

  .pannelBtns {
    display: flex;
    align-items: center;
    justify-content: end !important;
    gap: 10px;
    width: 100%;
    text-transform: none;
    color: var(--fadedBlack);
    font-size: 16px;
    color: black !important;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }

  .mobilesearchBarHeader {
    /* width: 100%; */
    /* max-width: 500px; */
    display: block;
    color: black !important;
    align-items: center;
    background: rgba(255, 255, 255, 0.3);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.3);
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.5);
    padding: 10px 0px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    /* padding-right: 100px; */
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 16px;
    margin-right: 16px;
  }
}
